/*!
 *
 * Allegro 2018
 *
 */
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

a {
  color: #007bff; }

.alerthandler {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 99999;
  padding: 1.5rem 0; }

.badge--product {
  position: absolute;
  bottom: 20px;
  left: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4); }

.card-img-top--product {
  position: relative; }

.card-body--product {
  flex-grow: 0; }

@media (max-width: 575.98px) {
  .container--navbar {
    padding: 0; } }

@media (max-width: 991.98px) {
  .container-sticky--md {
    top: 0;
    left: 0;
    position: -webkit-sticky;
    position: sticky;
    z-index: 555; } }

.products__hidden .js-drag-item:not(:hover) {
  opacity: 0.5; }
  .products__hidden .js-drag-item:not(:hover).is-dragging {
    opacity: 1; }

.product {
  position: relative; }
  .product:hover, .product:focus {
    opacity: 1; }
  .product__toggler {
    position: absolute;
    top: 0;
    right: 0; }
  .product__btn-image {
    padding: 0;
    border: none;
    font-family: inherit;
    cursor: pointer; }
    .product__btn-image:focus {
      outline: 0.2rem solid rgba(38, 143, 255, 0.5); }
  .product__remove:not(:disabled):hover svg, .product__remove:not(:disabled):focus svg {
    fill: #fff; }
  .product__customer {
    position: relative; }
  .product__posting {
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: 555; }
  .product__bottom {
    display: flex;
    flex-direction: column;
    height: 100%; }
    .product__bottom .collapse.show {
      display: flex;
      height: 100%;
      min-height: 300px;
      position: relative;
      overflow: auto; }
  .product__members {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100%; }

.js-drag {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  /* (Optional) Apply a "closed-hand" cursor during drag operation. */ }
  .js-drag.draggable-source--is-dragging {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing; }

.incdec {
  display: flex; }
  .incdec__button {
    font-family: inherit;
    font-size: 20px;
    border: 1px solid var(--primary);
    background: white;
    border-radius: 50%;
    color: black;
    cursor: pointer;
    width: 2.3em;
    height: 2.3em; }
    .incdec__button:hover, .incdec__button:focus {
      border-color: var(--primary);
      color: #fff;
      background: var(--primary); }
      .incdec__button:hover svg path, .incdec__button:focus svg path {
        fill: #fff; }
    .incdec__button:focus {
      outline: none;
      background: #0062cc; }
    .incdec__button:disabled, .incdec__button.disabled {
      color: #6c757d;
      border-color: #e9ecef;
      background: #e9ecef;
      color: #fff;
      cursor: default; }
      .incdec__button:disabled svg path, .incdec__button.disabled svg path {
        fill: #6c757d; }
  .incdec__label {
    background: white;
    display: flex;
    width: 60px;
    max-width: 100%;
    margin: 0 0.5em; }
  .incdec__field {
    font-family: inherit;
    font-size: 14px;
    background: none;
    border: none;
    padding: 0.5em;
    width: 100%;
    text-align: center; }
    .incdec__field::-webkit-inner-spin-button, .incdec__field::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0; }
    .incdec__field:disabled, .incdec__field.disabled {
      background: #e9ecef; }
  .incdec--product {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    padding: 15px; }

.sum .row:hover, .sum .row:focus {
  background: var(--light); }

.tracking__list {
  padding: 0 60px; }
  @media (min-width: 992px) {
    .tracking__list {
      min-width: 340px; } }

.tracking__item {
  position: relative; }

.tracking__item--finished:before, .tracking__item--current:before {
  content: "";
  position: absolute;
  display: inline-flex;
  margin: auto;
  left: -50px;
  top: 0;
  bottom: 0;
  width: 0.5em;
  height: 1em;
  background-color: transparent;
  border-bottom: 3px solid #28a745;
  border-right: 3px solid #28a745;
  transform: translate3d(75%, -6%, 0) rotate(45deg); }

.tracking__item--current {
  font-weight: bold; }

.tick:before {
  content: "";
  display: inline-flex;
  margin-right: 1em;
  width: 0.5em;
  height: 1em;
  background-color: transparent;
  border-bottom: 3px solid #28a745;
  border-right: 3px solid #28a745;
  transform: translate3d(75%, -6%, 0) rotate(45deg); }

.logo {
  text-align: center;
  display: inline-block;
  max-width: 60%; }
  @media (min-width: 576px) {
    .logo {
      text-align: left;
      width: auto; } }
  .logo__inner {
    max-width: 120px; }
    @media (min-width: 330px) {
      .logo__inner {
        max-width: 150px; } }
    @media (min-width: 576px) {
      .logo__inner {
        max-width: 160px; } }
    @media (min-width: 768px) {
      .logo__inner {
        max-width: 170px; } }
    @media (min-width: 992px) {
      .logo__inner {
        max-width: 200px; } }
  .logo__image {
    width: 100%;
    height: auto;
    display: block;
    max-height: 40px; }

.price {
  white-space: nowrap; }

.dropzone {
  border: 2px dashed #007bff;
  border-radius: 5px;
  background: white;
  padding: 2rem;
  margin-bottom: 1rem; }
  @media (min-width: 768px) {
    .dropzone__motif:hover .dropzone__delete, .dropzone__motif:focus .dropzone__delete {
      opacity: 1; } }
  .dropzone__media {
    width: 100%;
    position: relative;
    border-radius: 2px;
    border: 1px solid #eaeaea;
    padding: 2rem 1rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 0;
    overflow: hidden;
    background-color: #eee;
    background-image: linear-gradient(45deg, #bfbfbf 25%, transparent 25%, transparent 75%, #bfbfbf 75%, #bfbfbf), linear-gradient(45deg, #bfbfbf 25%, transparent 25%, transparent 75%, #bfbfbf 75%, #bfbfbf);
    background-size: 20px 20px;
    background-position: 0 0, 30px 30px; }
    @media (min-width: 768px) {
      .dropzone__media {
        padding: 1rem; } }
    @supports (object-fit: contain) {
      .dropzone__media {
        padding-bottom: 50%;
        min-height: 140px; }
        .dropzone__media img {
          object-fit: contain;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0; } }
  .dropzone__delete {
    position: absolute;
    top: 0;
    right: 0; }
    @media (min-width: 768px) {
      .dropzone__delete {
        opacity: 0; } }
  .dropzone__icon {
    fill: #fff; }
  .dropzone--active, .dropzone--accept, .dropzone--reject {
    border-style: solid; }
  .dropzone--accept {
    border-color: #28a745; }
  .dropzone--reject {
    border-color: #dc3545; }

.member__group + .member__group {
  margin-top: 1rem;
  padding-top: 1rem; }

.create {
  position: fixed;
  bottom: 15px;
  right: 15px;
  border-radius: 50%;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1), 10px 10px 30px rgba(0, 0, 0, 0.1); }
  @media (min-width: 768px) {
    .create {
      bottom: 30px;
      right: 30px; } }
  .create__button {
    width: 45px;
    height: 45px;
    padding: 0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media (min-width: 768px) {
      .create__button {
        width: 50px;
        height: 50px; } }

.btn {
  /*
    [data-toggle="buttons"] &.active {
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
    */ }
  [data-toggle="buttons"] .btn.active {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.form-control[contenteditable="true"] {
  height: auto; }

.form-control.is-focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.form-control .form-control-composite:focus {
  outline: none; }

.DayPickerInput.form-control {
  padding: 0;
  width: 100%; }
  .DayPickerInput.form-control input {
    border: none;
    padding: 0;
    background: none;
    width: 100%;
    padding: 0.375rem 0.75rem; }
    .DayPickerInput.form-control input:focus {
      outline: none; }

.badge-text {
  font-size: 100%;
  font-weight: normal; }

.pagetitle .badge {
  vertical-align: super;
  font-size: 0.6em;
  padding-top: 0.4em; }

.modal-title {
  font-weight: bold; }

.modal--product .modal-title {
  font-weight: normal; }

@media (min-width: 576px) {
  .modal--product .modal-dialog {
    max-width: 700px; } }

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: #fff; }

.slick-slide > div {
  display: block;
  font-size: 0; }
  .slick-slide > div > * {
    font-size: 1rem; }

.slick-slide .img-fluid {
  width: 100%; }

.slick-arrow {
  position: absolute;
  display: block;
  top: 50%;
  z-index: 2;
  color: white;
  text-transform: uppercase;
  padding: 9px 12px;
  background-color: rgba(255, 255, 255, 0.5);
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
  text-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.1);
  opacity: 1;
  cursor: pointer;
  transition: opacity 150ms ease, border 300ms ease-in-out;
  transform: translateY(-50%);
  line-height: 1; }
  .slick-arrow:hover, .slick-arrow:focus {
    outline: none; }
  .slick-arrow:not(.slick-disabled):hover, .slick-arrow:not(.slick-disabled):focus {
    border-color: white; }

.slick-prev {
  left: 2em; }

.slick-next {
  right: 2em; }

.slick-disabled {
  opacity: 0.5;
  cursor: default; }

.slick-dots {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 50%;
  display: inline-flex;
  list-style: none;
  transform: translateX(-50%);
  list-style: none;
  padding: 0; }
  .slick-dots .slick-active button {
    background-color: #007bff; }

.slick-dots li {
  display: inline-flex; }
  .slick-dots li button {
    width: 9px;
    height: 9px;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 0;
    border-radius: 50%;
    border: 2px solid transparent;
    transition: all 300ms ease-in-out;
    cursor: pointer;
    line-height: 0;
    box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
    margin: 0 0.25em;
    color: transparent; }
    .slick-dots li button:hover, .slick-dots li button:focus {
      outline: none; }

.slick--dark .slick-arrow {
  border-color: #343a40; }
  .slick--dark .slick-arrow:not(.slick-disabled):hover, .slick--dark .slick-arrow:not(.slick-disabled):focus {
    border-color: #343a40; }

.slick--dark .slick-dots li button {
  background-color: rgba(52, 58, 64, 0.5); }

.slick--dark .slick-dots .slick-active button {
  background-color: #007bff; }

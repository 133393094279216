.placer {
  position: relative; }
  .placer__logo {
    background-color: #eee;
    background-image: linear-gradient(45deg, #bfbfbf 25%, transparent 25%, transparent 75%, #bfbfbf 75%, #bfbfbf), linear-gradient(45deg, #bfbfbf 25%, transparent 25%, transparent 75%, #bfbfbf 75%, #bfbfbf);
    background-size: 20px 20px;
    background-position: 0 0, 30px 30px; }
    @supports (object-fit: contain) {
      .placer__logo {
        position: relative;
        width: 100%;
        padding-bottom: 25%;
        min-height: 100px; }
        .placer__logo img {
          object-fit: contain;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0; } }
  .placer__logo--active {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.editor {
  background: white;
  position: relative;
  display: inline-flex;
  cursor: default; }
  .editor__buttons {
    position: absolute;
    bottom: 0;
    left: 0; }
    .placer:not(.show-overlays) .editor__buttons {
      visibility: hidden;
      opacity: 0; }
  .editor__loading, .editor__empty {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
    text-align: center; }
  .editor__empty {
    background: rgba(255, 255, 255, 0.8); }
  .editor__loading {
    background: #fff;
    border-radius: 3px;
    z-index: 555; }

.upper-canvas {
  cursor: default !important; }

.placeholder {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  min-width: 20px;
  min-height: 20px; }
  .placeholder--active-false {
    cursor: default;
    pointer-events: none; }
  .placeholder.is-hidden {
    display: none; }
  .placeholder__marker {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    background: white;
    border: none;
    color: #28a745;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    font-weight: bold;
    pointer-events: none;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1); }
    .placeholder__marker:before, .placeholder__marker:after {
      content: "";
      display: block;
      position: absolute;
      background: #28a745;
      pointer-events: none; }
    .placeholder__marker:before {
      width: 11px;
      height: 2px;
      top: 50%;
      left: 0;
      transform: translate3d(50%, -50%, 0); }
    .placeholder__marker:after {
      height: 11px;
      width: 2px;
      left: 50%;
      top: 0;
      transform: translate3d(-50%, 50%, 0); }
  .placeholder__area {
    flex-grow: 1;
    width: 100%;
    border: 2px dashed #007bff;
    background: transparent;
    cursor: pointer; }
    .placeholder__area:hover, .placeholder__area:focus {
      border-color: #28a745;
      outline: none; }
    .placeholder__area--active-true:hover, .placeholder__area--active-true:focus {
      border-color: #28a745; }
    .placeholder__area--active-false {
      cursor: default;
      pointer-events: none; }
      .placeholder__area--active-false:hover, .placeholder__area--active-false:focus {
        border-color: #007bff; }
  .placer:not(.show-overlays) .placeholder {
    visibility: hidden;
    opacity: 0; }

.prodnav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem; }
  @media (min-width: 1200px) {
    .prodnav {
      margin-bottom: 0;
      position: absolute;
      top: 50%;
      transform: translate3d(0, -50%, 0); } }
  .prodnav__button:disabled {
    opacity: 0.4;
    background: #efefef; }
